import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

class LocationInfo extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            city: props.city,
            country: props.country
        }
    }
    
    componentWillReceiveProps(props) 
    {
        this.setState({city: props.city, country:props.country });
    }

    render()
    {
        if(this.state.city != "" && this.state.country != "")
        {
            return <div className="location-info">
                        <FontAwesomeIcon icon={solid('location-dot')} /> {this.state.city} ({this.state.country})
                    </div>  
        }
        else
            return "";
    }
}

export default LocationInfo;