import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

class ForecastKpWeather extends React.Component
{

    getKpForecast(date,time)
    {
        var output = null;

        var formattedDate = date+"T"+time+"+00:00";
        var formattedDate = new Date(formattedDate);

        var dayIndex = this.props.kp.dates.indexOf(date+"T00:00:00+00:00");
        if(dayIndex >= 0) //data trovata
        {
            for(var i = 0; i< this.props.kp.values[dayIndex].length; i++)
            {
                if(formattedDate > new Date(this.props.kp.values[dayIndex][i].start) && formattedDate <= new Date(this.props.kp.values[dayIndex][i].end) )
                {
                    output = {value: this.props.kp.values[dayIndex][i].value, colour: this.props.kp.values[dayIndex][i].colour};
                    this.props.kp.values[dayIndex].splice(i, 1); //rumiovo dalle previsioni l'elemento trovato, migliori performance
                    break;
                }
            }
        }
        
        return output;
    }

    render()
    {
        var forecastDay = [];
        
        if(this.props.weather != null && this.props.kp != null)
        {
            for(var i = 0; i< this.props.weather.list.length; i++)
            {
                var item = this.props.weather.list[i];

                var date = item.dt_txt.split(" ")[0];
                var time = item.dt_txt.split(" ")[1];

                var kpForecast = this.getKpForecast(date,time);
                if(kpForecast == null)
                    continue;

                forecastDay.push({
                    index: i,
                    date: date,
                    time: time,
                    kpValue: kpForecast.value,
                    kpColour: kpForecast.colour,
                    weatherMain: item.weather[0].main,
                    weatherDescription: item.weather[0].description,
                    icon: item.weather[0].icon,
                    temperature: Math.round(item.main.temp),
                    rain: Math.round(item.pop*100),
                    wind: Math.round(item.wind.speed*3.6, 2),
                });
            }
        }
        
        if(forecastDay.length > 0)
        {
            return <div className="forecast-weather-data">
                        <div className="kp-title">KP & Weather Forecast</div>
                        <WeatherList data={forecastDay} />
                    </div>
        }
        else
        {
            return <div className="forecast-weather-data">
                        <div className="kp-title">KP & Weather Forecast</div>
                        <div className="no-forecast-available">Insert your location and press "Update"</div>
                    </div> 
        }
    }
}

const WeatherList = ({ data }) => (
    data.map( item => {

        return <WeatherItem key={item.index} item={item} />
    })
);

const WeatherItem = ({ item }) => (
    <div className="weather-item">
            <div className="forecast-title">{item.date}<br/>{item.time}</div>
            <div className={"forecast-kp kp-round-bg bg-"+item.kpColour}>{item.kpValue}</div>
            <div className="forecast-image"><img src={"weather-icon/"+item.icon+"@2x.png"} /></div>
            <div className="forecast-main">{item.weatherMain}<br/><span className="main-subtitle">{item.weatherDescription}</span></div>
            <div className="forecast-temperature"><FontAwesomeIcon icon={solid('temperature-half')} /> {item.temperature} °C</div>
            <div className="forecast-rain"><FontAwesomeIcon icon={solid('droplet')} /> {item.rain} %</div>
            <div className="forecast-wind"><FontAwesomeIcon icon={solid('wind')} /> {item.wind} km/h</div>
    </div>
);

export default ForecastKpWeather;