import React from 'react';
import LocationBox from './LocationBox.js';
import LocationInfo from './LocationInfo.js';
import ForecastKpWeather from './ForecastKpWeather.js';
import Cookies from 'universal-cookie';

const axios = require('axios').default; //chiamate API

class Dashboard extends React.Component 
{
    /* 
        account API 
        matteo.teknet@gmail.com - Tek@833621
    */

    constructor(props)
    {
        super(props);
        this.state = {
            lat: '',
            lon: '',
            responseKp: null,
            responseForecastKp: null,
            weatherForecast: null,
            weatherCity: "",
            weatherCountry: "",
        }
    }

    handleChangeLocation = (latitude, longitude) => {
        this.setState({lat: latitude, lon: longitude}); //coordinate ricavate dal componente LocationBox
        //this.updateKp(); //aggiornamento componente KP
        this.forecastKp(); //previsione del KP per i prossimi 3 giorni
        this.weatherForecast(); //previsioni del tempo per i prossimi giorni
    }

    /* Chiamate */

    updateKp()
    {
        /*axios.get('http://cms.teknet.it/webapp/api/caller.php', {
            params: {
                url: 'https://api.auroras.live/v1/',
                type: 'ace',
                data: 'probability',
                lat: this.state.lat,
                long: this.state.lon
            }
          })
          */
        axios.get('https://api.auroras.live/v1/', {
            params: {
                type: 'ace',
                data: 'probability',
                lat: this.state.lat,
                long: this.state.lon
            }
          })
          .then(response => {
                this.setState({responseKp: response.data});
                console.log("responseKp updated");
          })
          .catch(function (error) {
                console.log(error);
          })
    }

    forecastKp()
    {
        /*axios.get('http://cms.teknet.it/webapp/api/caller.php', {
            params: {
                url: 'https://api.auroras.live/v1/',
                type: 'ace',
                data: 'threeday',
                lat: this.state.lat,
                long: this.state.lon
            }
          })*/
          axios.get('https://api.auroras.live/v1/', {
            params: {
                type: 'ace',
                data: 'threeday',
                lat: this.state.lat,
                long: this.state.lon
            }
          })
          .then(response => {
                this.setState({responseForecastKp: response.data});
                console.log("responseForecastKp updated");
          })
          .catch(function (error) {
                
                console.log(error);
          })
    }

    weatherForecast()
    {
        /*axios.get('http://cms.teknet.it/webapp/api/caller.php', {
            params: {
                url: 'api.openweathermap.org/data/2.5/forecast',
                appId: "268479072541aeac59ca03feaed8adc8",
                lat: this.state.lat, 
                lon: this.state.lon,
                units: "metric",
            }
          })*/
        axios.get('https://api.openweathermap.org/data/2.5/forecast', {
            params: {
                appId: "268479072541aeac59ca03feaed8adc8",
                lat: this.state.lat, 
                lon: this.state.lon,
                units: "metric",
            }
          }) 
        .then(response => {
                if(response.data.city != null)
                {
                    this.setState({weatherForecast: response.data, weatherCity: response.data.city.name, weatherCountry: response.data.city.country});
                    console.log("weatherForecast updated");
                }
        })
        .catch(function (error) {
                console.log(error);
        })
    }

    /* Cookies per scambio dati tra le pagine */
    componentDidMount()
    {
        var cookieLat = this.getCookie("lat");
        var cookieLon = this.getCookie("lon");
        console.log(cookieLat, cookieLon);
        if(cookieLat != "" && cookieLon != "")
        {
            this.setState({lat: cookieLat, lon: cookieLon});
        }
    }

    getCookie(cname)
    {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
    }

    /** Render del componente */
    render() 
    {
        return <div className="container">
                    <div className='logo'><img src="logo.png" alt="Borealisi" /></div>
                    <div className="title">Northern-Lights forecast</div>
                    <div className="subtitle">Insert your location to get KP and weather forecast.</div>
                    <LocationBox 
                        onChangeLocation={this.handleChangeLocation}
                        lat={this.state.lat}
                        lon={this.state.lon}
                    />
                    <LocationInfo
                        city={this.state.weatherCity}
                        country={this.state.weatherCountry}
                    />
                    <div className='forecast-container'>
                        <ForecastKpWeather
                            kp={this.state.responseForecastKp}
                            weather={this.state.weatherForecast}
                        />
                    </div>
                </div>
    }
}

export default Dashboard;