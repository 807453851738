import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import { Link } from "react-router-dom";
import Cookies from 'universal-cookie';

class LocationBox extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            lat : props.lat,
            lon : props.lon
        };

        this.updateLatitude = this.updateLatitude.bind(this);
        this.updateLongitude = this.updateLongitude.bind(this);
        this.setupLocation = this.setupLocation.bind(this);
        this.getUserLocation = this.getUserLocation.bind(this);
    }

    componentWillReceiveProps(props) 
    {
        this.setState({lat: props.lat, lon:props.lon });
    }

    updateLatitude(event)
    {
        this.setState({lat: event.target.value});
    }

    updateLongitude(event)
    {
        this.setState({lon: event.target.value});
    }

    setupLocation()
    {
        this.props.onChangeLocation(this.state.lat, this.state.lon);
    }

    getUserLocation()
    {
        navigator.geolocation.getCurrentPosition((position) => { 

            const cookies = new Cookies();
            cookies.set('lat', position.coords.latitude, { path: '/' });
            cookies.set('lon', position.coords.longitude, { path: '/' });

            this.setState({lat: position.coords.latitude});
            this.setState({lon: position.coords.longitude});
            this.props.onChangeLocation(position.coords.latitude, position.coords.longitude);
          });
    }

    render()
    {
        return <div className="location-box">
                    <div className="form-piece">
                        <label className="form-label">Latitude</label>
                        <input className="form-input" type="text" name="lat" defaultValue={this.state.lat} onChange={this.updateLatitude} />
                    </div>
                    <div className="form-piece">
                        <label className="form-label">Longitude</label>
                        <input className="form-input" type="text" name="lon" defaultValue={this.state.lon} onChange={this.updateLongitude} />
                    </div>
                    <div className="form-buttons">
                        <div className="form-button" onClick={this.setupLocation}><FontAwesomeIcon icon={solid('arrows-rotate')} /> Update</div>
                        <div className="form-button" onClick={this.getUserLocation}><FontAwesomeIcon icon={solid('location-crosshairs')} /> Detect</div>
                        <Link to="/map" className="form-button"><FontAwesomeIcon icon={solid('map-location')} /> Map</Link>
                    </div>
                </div>
    }
}

export default LocationBox;