import React from 'react';
import { MapContainer, TileLayer, Marker, Popup, MapConsumer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from "leaflet";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import Cookies from 'universal-cookie';


var marker; //variabile globale per avere un solo marker
class Map extends React.Component
{
    constructor(props) {
        super(props);
    }

    setupLocation()
    {
        const cookies = new Cookies();
        cookies.set('lat', marker._latlng.lat, { path: '/' });
        cookies.set('lon', marker._latlng.lng, { path: '/' });
    }

    render()
    {
        const position = [47.3022675, 11.8571976];

        return  <div className='map-container'>
                    <div className='map-title'>Select your location</div>
                    <div className='map-subtitle'>Touch the map to setup your location.<br/>Then click "Done" button.</div>
                    <div className="location-button">
                        <Link to="/" className='button-back'><FontAwesomeIcon icon={solid('arrow-left')} /> Back</Link>
                        <Link to="/ "className='button-done' onClick={this.setupLocation}><FontAwesomeIcon icon={solid('check')} /> Done</Link>
                    </div>
                    <MapContainer center={position} zoom={3} className="mappa" onClick={this.mapClicked}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <MapConsumer>
                            {(map) => {
                                map.on("click", function (e) {
                                    
                                    if (marker) {
                                        map.removeLayer(marker);
                                    }
                                    
                                    marker = new L.Marker(e.latlng).addTo(map); 
                                });
                                return null;
                            }}
                        </MapConsumer>
                    </MapContainer>
                </div>
    }
}

export default Map;