import React from 'react';
import { Route, Routes} from 'react-router'
import Dashboard from './Dashboard.js';
import Map from './Map.js';

class Main extends React.Component
{
  constructor(props)
  {
      super(props);
  }

  render()
  {
        return <Routes> {/* The Switch decides which component to show based on the current URL.*/}
            <Route path='/' element={<Dashboard/>} />
            <Route path='/map' element={<Map/>} />
        </Routes>
    
  }
}

export default Main;